import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../../components/Layout'
import ServiceFooter from '../../components/ServiceFooter'

const Remote = () => {
  const {
    site: {
      siteMetadata: { title },
    },
  } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <Layout>
      <Helmet>
        <html className="temp" lang="ja" />
        <title>{`遠隔管理システム | ${title}`}</title>
        <meta
          name="description"
          content="アイテックの「Web版遠隔管理システムi-BARMS」はインターネット接続があれば、Webブラウザでどこからでも駐車場情報を確認できるアイテック製駐車場機器専用アプリケーションです。"
        />
      </Helmet>

      <div className="common-header clearfix">
        <p className="category pc-only">製品・サービス</p>
        <h1>遠隔管理システム</h1>
        <p className="category sp-only">製品・サービス</p>

        <div className="breadcrumb">
          <li>
            <Link to={`/`}>トップページ</Link>
          </li>
          /
          <li>
            <Link to={`/service`}>製品・サービス</Link>
          </li>
          /<li>遠隔管理システム</li>
        </div>
      </div>

      <div className="main-wrapper remote">
        <div className="row">
          <div className="container empty"></div>
        </div>

        <div className="row">
          <div className="h-wrapper">
            <h2>i-BARMS：アイバーンズ</h2>
            <div className="common-btn pc-only">
              <a
                href="/assets/files/whitepaper/itc-webremote.pdf"
                target="_blank"
              >
                カタログダウンロード
              </a>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="container top">
            <img
              className="ibarms-logo"
              src="/assets/images/common/i-barms-logo.png"
              alt="ibarmsロゴ"
            />

            <h4>
              アイテックの「Web版遠隔管理システムi-BARMS」はインターネット接続があれば、Webブラウザでどこからでも駐車場情報を確認できるアイテック製駐車場機器専用アプリケーションです。
            </h4>

            <h5>ここがアイテック！</h5>

            <div className="summary full">
              <ul>
                <li>駐車場に出向くことなく、リアルタイムな遠隔管理が可能</li>
                <li>
                  駐車状況の確認/監視はもちろん、精算機の管理や駐車場運営のための分析ツールを無料で利用可能
                  <ul className="small-points">
                    <li>防犯カメラでリアルタイムの映像から過去の映像を閲覧</li>
                    <li>
                      売上の状況から精算機の釣り銭状況・ロール紙の残量まで、様々なデータをいつでも確認
                    </li>
                    <li>
                      ゲートバーやロック板の上げ下げなど、駐車場機器も操作可能
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="h-wrapper">
            <h3>システム画面</h3>
          </div>
        </div>

        <div className="row">
          <div className="container cont-01">
            <picture>
              <source
                type="image/webp"
                srcset="/assets/images/common/service-remote-01.webp"
              />
              <img
                className="remote-01"
                src="/assets/images/common/service-remote-01.jpg"
                height="869"
                alt=""
              />
            </picture>

            <p className="num">
              ①
              ：入庫中の車両台数をリアルタイムで確認できます。必要に応じて手動による入庫台数の変更も可能です
            </p>

            <p className="num">
              ②：精算機の状態を確認できます。売上、現金入金、釣銭合計、利用台数、釣銭の金種別保有枚数、等の確認に加え、ドアスイッチ、ビルバリ、コインメック、プリンター等が正常に稼働しているかどうかの把握が可能です。
            </p>

            <p className="num">
              ③：現在入庫中の車両の入庫時刻を確認出来ます。本システムは防犯カメラと連動しているため、各車両の入庫時の画像を確認可能です。長期駐車の発見、対策に有効です。（注1）
            </p>

            <p className="num">
              ④：リアルタイム監視機能
              <br />
              精算トラブル時に必要になる釣銭払い出しが実行できます。
              <br />
              ゲートシステムでは駐車券紛失時の精算料金の変更や、ゲートバーの上げ下げがボタンひとつで実行可能。リアルタイムカメラの画像を見ながら、安全に操作を行う事が出来ます。
            </p>

            <p className="notes">
              ※注1：ロックレス用遠隔管理システムは、不払い出庫車両の入庫時画像、出庫時画像の確認が可能です。
            </p>

            <h5>防犯カメラ リアルタイム映像</h5>

            <div className="separate-area">
              <div className="box">
                <picture>
                  <source
                    type="image/webp"
                    srcset="/assets/images/common/service-remote-02.webp"
                  />
                  <img
                    className="remote-02"
                    src="/assets/images/common/service-remote-02.jpg"
                    height="494"
                    alt=""
                  />
                </picture>
                <p>昼間画像</p>
              </div>
              <div className="box">
                <picture>
                  <source
                    type="image/webp"
                    srcset="/assets/images/common/service-remote-03.webp"
                  />
                  <img
                    className="remote-03"
                    src="/assets/images/common/service-remote-03.jpg"
                    height="247"
                    alt=""
                  />
                </picture>
                <p>夜間画像</p>
              </div>
            </div>

            <h5>設定画面</h5>

            <picture>
              <source
                type="image/webp"
                srcset="/assets/images/common/service-remote-04.webp"
              />
              <img
                className="remote-04"
                src="/assets/images/common/service-remote-04.jpg"
                height="902"
                alt=""
              />
            </picture>

            <h5>売上月報</h5>

            <picture>
              <source
                type="image/webp"
                srcset="/assets/images/common/service-remote-05.webp"
              />
              <img
                className="remote-05"
                src="/assets/images/common/service-remote-05.jpg"
                height="602"
                alt=""
              />
            </picture>
          </div>
        </div>

        <ServiceFooter />
      </div>
    </Layout>
  )
}

export default Remote
